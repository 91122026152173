import React, { createContext, useState, useEffect, ReactNode } from "react";
import { colorSchemes } from "../Utils/Styles/Themes";

interface ThemeContextType {
  selectedScheme: number;
  setSelectedScheme: React.Dispatch<React.SetStateAction<number>>;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [selectedScheme, setSelectedScheme] = useState<number>(0);

  const updateCSSVariables = (primary: string, secondary: string, background: string) => {
    const darkenColor = (color: string, percent: number): string => {
      const num = parseInt(color.slice(1), 16);
      const amt = Math.round(2.55 * percent);
      const R = (num >> 16) + amt;
      const G = (num >> 8 & 0x00FF) + amt;
      const B = (num & 0x0000FF) + amt;
      return `#${(
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      ).toString(16).slice(1)}`;
    };

    const lightenColor = (color: string, percent: number): string => {
      const num = parseInt(color.slice(1), 16);
      const amt = Math.round(2.55 * percent);
      const R = (num >> 16) - amt;
      const G = (num >> 8 & 0x00FF) - amt;
      const B = (num & 0x0000FF) - amt;
      return `#${(
        0x1000000 +
        (R > 0 ? (R < 255 ? R : 255) : 0) * 0x10000 +
        (G > 0 ? (G < 255 ? G : 255) : 0) * 0x100 +
        (B > 0 ? (B < 255 ? B : 255) : 0)
      ).toString(16).slice(1)}`;
    };

    const getLuminance = (color: string): number => {
      const rgb = parseInt(color.slice(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = rgb & 0xff;
      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const getContrastColor = (color: string): string => {
      return getLuminance(color) > 140 ? "#000000" : "#FFFFFF";
    };

    const primaryHover = darkenColor(primary, -10);
    const secondaryHover = darkenColor(secondary, -10);
    const primaryLight = lightenColor(primary, -10);
    const secondaryLight = lightenColor(secondary, -10);
    const backgroundDark = darkenColor(background, -10);
    const backgroundDarker = darkenColor(background, -25);
    const backgroundLight = lightenColor(background, -10);
    const backgroundLighter = lightenColor(background, -10);

    const primaryContrastText = getContrastColor(primary);
    const secondaryContrastText = getContrastColor(secondary);

    document.documentElement.style.setProperty("--primary-color", primary);
    document.documentElement.style.setProperty("--primary-color-light", primaryLight);
    document.documentElement.style.setProperty("--primary-color-hover", primaryHover);
    document.documentElement.style.setProperty("--text-color-primary", primaryContrastText);
    document.documentElement.style.setProperty("--secondary-color", secondary);
    document.documentElement.style.setProperty("--secondary-color-light", secondaryLight);
    document.documentElement.style.setProperty("--secondary-color-hover", secondaryHover);
    document.documentElement.style.setProperty("--text-color-secondary", secondaryContrastText);
    document.documentElement.style.setProperty("--background-color", background);
    document.documentElement.style.setProperty("--background-color-dark", backgroundDark);
    document.documentElement.style.setProperty("--background-color-darker", backgroundDarker);
    document.documentElement.style.setProperty("--background-color-light", backgroundLight);
    document.documentElement.style.setProperty("--background-color-lighter", backgroundLighter);
  };

  useEffect(() => {
    const savedScheme = localStorage.getItem("selectedScheme");
    const schemeIndex = savedScheme ? parseInt(savedScheme, 10) : 0;
    setSelectedScheme(schemeIndex);
    const { primaryColor, secondaryColor, backgroundColor } = colorSchemes[schemeIndex];
    updateCSSVariables(primaryColor, secondaryColor, backgroundColor);
  }, []);

  useEffect(() => {
    const { primaryColor, secondaryColor, backgroundColor } = colorSchemes[selectedScheme];
    updateCSSVariables(primaryColor, secondaryColor, backgroundColor);
    localStorage.setItem("selectedScheme", selectedScheme.toString());
  }, [selectedScheme]);

  return (
    <ThemeContext.Provider value={{ selectedScheme, setSelectedScheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
