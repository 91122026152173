import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "../../../Hooks/useDispatch";
import { fetchDraftBlogs, deleteBlogDraft, fetchPublishedBlogs } from "../../../Features/Blog/BlogThunk";
import { RootState } from "../../../Features/store";
import { useNavigate } from "react-router-dom";
import SimpleCard from "../../../Components/Cards/SimpleCard/SimpleCard";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Button from "../../../Components/UI Components/Buttons/Button/Button";
import "./BlogDashboard.scss";

const BlogDashboard: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { drafts, published, loading, error } = useSelector((state: RootState) => state.blogs);

    // Fetch drafts on component mount
    useEffect(() => {
        dispatch(fetchPublishedBlogs());
        dispatch(fetchDraftBlogs());
    }, [dispatch]);

    // Handle edit action
    const handleEdit = (id: string) => {
        navigate(`/blog-creator/${id}`);
    };

    // Handle create action
    const handleCreate = () => {
        navigate(`/blog-creator`);
    };

    // Handle delete action
    const handleDelete = (id: string) => {
        dispatch(deleteBlogDraft(id));
    };

    const formatDate = (timestamp: number | undefined) => {
        if (!timestamp) return "N/A";
        const date = new Date(timestamp);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    return (
        <div className="blog-dashboard">
            <h1>Blog Dashboard</h1>
            <Button theme="success" onClick={handleCreate} className="create-blog-btn">
                Create New Blog
            </Button>
            <h2>Published</h2>
            {loading && <p>Loading blogs...</p>}
            {error && <p className="error">{error}</p>}
            <div className="draft-list">
                {!loading && (!published || published.length === 0) ? (
                    <p>No blogs yet!</p>
                ) : (
                    published?.map((blog) => (
                        <SimpleCard
                            key={blog?.id}
                            title={blog?.title}
                            className="draft-card"
                            useIconButtons={true}
                            actionButtons={[
                                {
                                    label: "Edit",
                                    onClick: () => handleEdit(blog?.id as unknown as string),
                                    icon: AiOutlineEdit,
                                    theme: "success",
                                },
                                {
                                    label: "Delete",
                                    onClick: () => handleDelete(blog?.id as unknown as string),
                                    icon: AiOutlineDelete,
                                    theme: "error",
                                },
                            ]}
                        >
                            <p>Author: {blog?.author}</p>
                            <p>Published: {formatDate(blog?.publishedAt as unknown as number)}</p>
                        </SimpleCard>
                    ))
                )}
            </div>
            <h2>Drafts</h2>
            {loading && <p>Loading drafts...</p>}
            {error && <p className="error">{error}</p>}
            <div className="draft-list">
                {drafts.length === 0 && !loading ? (
                    <p>No drafts available.</p>
                ) : (
                    drafts.map((draft) => (
                        <SimpleCard
                            key={draft.id}
                            title={draft.title}
                            className="draft-card"
                            useIconButtons={true}
                            actionButtons={[
                                {
                                    label: "Edit",
                                    onClick: () => handleEdit(draft.id as unknown as string),
                                    icon: AiOutlineEdit,
                                    theme: "primary",
                                },
                                {
                                    label: "Delete",
                                    onClick: () => handleDelete(draft.id as unknown as string),
                                    icon: AiOutlineDelete,
                                    theme: "error",
                                },
                            ]}
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default BlogDashboard;
