import React from 'react';
import SmIconButton from '../../UI Components/Buttons/SmallIconButton/SmallIconButton';
import {motion} from 'framer-motion';
import './AlbumCard.scss';
import { analytics } from '../../../firebase';
import { logEvent } from 'firebase/analytics';

interface AlbumCardProps {
    imageUrl: string;
    title: string;
    releaseDate: string;
    credits: string[];
    listenLinks?: { url?: string; icon: React.ComponentType; tooltip: string }[];
}

const AlbumCard: React.FC<AlbumCardProps> = ({ imageUrl, title, releaseDate, credits, listenLinks }) => {
    return (
        <motion.div
            className="album-card"
            whileHover={{ scale: 1.05 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
            onClick={() => document.querySelector('.album-info')?.classList.toggle('show-info')}
        >
            <div className="album-art" style={{ backgroundImage: `url(${imageUrl})` }}>
            </div>
            <div className="album-info">
                <h2 className="album-title">{title}</h2>
                <p className="release-date">Released: {releaseDate}</p>
                <ul className="album-credits">
                    {credits.map((credit, index) => (
                        <li key={index}>
                            <strong>{credit.split(':')[0]}:</strong> {credit.split(':')[1]}
                        </li>
                    ))}
                </ul>
                <div className="listen-links">
                    {listenLinks?.map((link, index) => (
                        <SmIconButton
                            key={index}
                            icon={link.icon}
                            href={link.url}
                            theme="primary"
                            className="link-button"
                            onClick={() => {           
                                logEvent(analytics, 'album_listen_link_click', {
                                  album_title: title,
                                  link_name: link.tooltip,
                                  link_url: link.url,
                                });
                                if (link.url) {
                                  window.open(link.url, '_blank', 'noopener,noreferrer');
                                }
                              }}
                        />
                    ))}
                </div>
            </div>
        </motion.div>
    );
};

export default AlbumCard;
