import React from "react";
import { Blog } from "../../../../../types";
import BlogEditor from "../../../../../Components/UI Components/BlogEditor/BlogEditor";

interface BlogFormProps {
  blog: Blog;
  onInputChange: (name: string, value: string) => void;
}

const BlogForm: React.FC<BlogFormProps> = ({ blog, onInputChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onInputChange(name, value);
  };

  const handleContentChange = (content: string) => {
    onInputChange("content", content);
  };

  return (
    <div className="blog-form">
      <div className="form-section">
        <h2>Blog Information</h2>
        <label>Blog Title *</label>
        <input
          type="text"
          name="title"
          value={blog.title}
          onChange={handleChange}
          placeholder="Enter blog title"
        />
        <label>SEO Description *</label>
        <textarea
          name="seoDescription"
          value={blog.seoDescription}
          onChange={handleChange}
          placeholder="Enter a brief description"
        />
        <label>Meta Keywords</label>
        <textarea
          name="metaKeywords"
          value={blog.metaKeywords}
          onChange={handleChange}
          placeholder="Enter keywords"
        />
      </div>
      <div className="metadata-section">
        <h2>Metadata</h2>
        <label>Author</label>
        <input
          type="text"
          name="author"
          value={blog.author}
          onChange={handleChange}
          placeholder="Enter the author name"
        />
        <label>Open Graph Title</label>
        <input
          type="text"
          name="ogTitle"
          value={blog.ogTitle}
          onChange={handleChange}
          placeholder="Enter Open Graph title"
        />
        <label>Open Graph Description</label>
        <textarea
          name="ogDescription"
          value={blog.ogDescription}
          onChange={handleChange}
          placeholder="Enter Open Graph description"
        />
        <label>Twitter Title</label>
        <input
          type="text"
          name="twitterTitle"
          value={blog.twitterTitle}
          onChange={handleChange}
          placeholder="Enter Twitter title"
        />
        <label>Twitter Description</label>
        <textarea
          name="twitterDescription"
          value={blog.twitterDescription}
          onChange={handleChange}
          placeholder="Enter Twitter description"
        />
        <label>Meta Image URL</label>
        <input
          type="text"
          name="metaImage"
          value={blog.metaImage}
          onChange={handleChange}
          placeholder="Enter the URL of the meta image"
        />
      </div>
      <div className="content-editor-section">
      <h2>Blog Content</h2>
        <BlogEditor
          content={blog.content}
          onContentChange={handleContentChange}
        />
      </div>
    </div>
  );
};

export default BlogForm;
