import React from "react";
import "./DashboardHeader.scss";

import { useDispatch} from 'react-redux';
import { AppDispatch} from '../../../Features/store';
import { useNavigate } from "react-router-dom";
import { persistor } from "../../../Features/store";

import { logout } from "../../../Features/Auth/AuthSlice";

import Button from "../../../Components/UI Components/Buttons/Button/Button";

const DashboardHeader: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleLogout = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(logout());
    persistor.purge();
    localStorage.removeItem("persist:root")
    navigate("/login");
  };

  return (
    <header className="dashboard-header">
      <h1>Dashboard</h1>
      <div className="header-right">
        <Button className="header-action" onClick={handleLogout}>Logout</Button>
      </div>
    </header>
  );
};

export default DashboardHeader;
