import React, { useContext } from "react";
import { colorSchemes } from "../../Utils/Styles/Themes";
import Select from "../UI Components/Select/Select";
import { ThemeContext } from "../../Contexts/ThemeContext";

const UserThemeSettings: React.FC = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("ThemeContext is undefined. Make sure to wrap this component with ThemeProvider.");
  }

  const { selectedScheme, setSelectedScheme} = themeContext;

  const options = colorSchemes.map((scheme, index) => ({
    value: index.toString(),
    label: scheme.name,
  }));

  return (
    <div className={`control-container`}>
      <Select
        theme="primary"
        options={options}
        value={selectedScheme.toString()}
        onChange={(value: string | number) => setSelectedScheme(parseInt(value as string, 10))}
        ariaLabel="Select Theme"
      />
    </div>
  );
};

export default UserThemeSettings;
