import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { login } from '../../Features/Auth/AuthThunks';
import { AppDispatch, RootState } from '../../Features/store';

import './Login.scss';
import { FaUser, FaLock } from 'react-icons/fa';
import Button from '../../Components/UI Components/Buttons/Button/Button';
import IconTextInput from '../../Components/UI Components/TextInput/IconTextInput/IconTextInput';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { loading, error, user } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Please login to continue</h2>
        <form>
        <div className="input-group">
            <IconTextInput
              type="email"
              id="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              icon={FaUser}
              theme='primary'
            />
        </div>

        <div className="input-group">
            <IconTextInput
              type="password"
              id="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              icon={FaLock}
              theme='primary'
            />
        </div>

        <div className="actions">
          <Button theme="primary" onClick={handleSubmit}>
          {loading ? 'Logging in...' : 'Login'}
          </Button>
        </div>
        </form>
        {error && <p className='error-message'>{error}</p>}
      </div>
    </div>
  );
};

export default LoginPage;
