import React, { useState, useEffect } from 'react';
import SmIconButton from '../../UI Components/Buttons/SmallIconButton/SmallIconButton';
import { FaTimes } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import './ImageModal.scss';

interface ImageModalProps {
  imageUrl: string;
  imageTitle?: string;
  thumbnailUrl?: string;
  className?: string;
  onClose: () => void;
}

const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, imageTitle, thumbnailUrl, className, onClose }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const handleImageError = () => {
    setHasError(true);
  };

  if (hasError) return null;

  return ReactDOM.createPortal(
    <motion.div
      className={`modal ${className || ''}`}
      onClick={onClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <SmIconButton
          className="close-button"
          icon={FaTimes}
          ariaLabel="Close button"
          onClick={onClose}
          theme='error'
        />
        <img
          src={imageUrl}
          alt={imageTitle || 'Image'}
          className="modal-image"
          loading="lazy"
          onError={handleImageError}
        />
      </motion.div>
    </motion.div>,
    document.getElementById('widget-portal') as HTMLElement
  );
};

export default ImageModal;
