import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { heroImages } from "../../Utils/resources/heroImages";
import { sections } from "../../Utils/resources/homeSections";
import Card from "../../Components/Cards/SimpleCard/SimpleCard";
import { Helmet } from "react-helmet-async";
import "./HomePage.scss";

const getRandomImage = () => heroImages[Math.floor(Math.random() * heroImages.length)];

const Home: React.FC = () => {
  const heroImage = getRandomImage();

  return (
    <div className="home-container">
      <Helmet>
      <title>Davin Chiupka</title>
      <meta name="description" content="Discover captivating photography, original compositions, and coding portfolio projects of Davin Chiupka." />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Davin Chiupka" />
      <meta property="og:description" content="Explore Davin Chiupka's music, photography, and web development portfolio." />
      <meta property="og:image" content="https://images.unsplash.com/photo-1500989145603-8e7ef71d639e?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
      </Helmet>
      <motion.div
        className="hero-section"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <motion.div 
          className="hero-content" 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <h1 className="hero-title">Step Into a World of Creativity</h1>
          <p className="hero-subtitle">
            Explore my passions in music, photography, and coding—all in one place.
          </p>
        </motion.div>
        <div className="overlay"></div>
      </motion.div>

      <div className="bottom-container">
        <motion.div className="about-me-section">
          <div className="about-me-box">
            <div className="about-me-content">
              <div className="about-me-image" />
              <div className="about-me-text">
                <h1 className="about-me-title">About Me</h1>
                <p className="about-me-description">
                Hello! I'm Davin Chiupka. My background in computer programming, audio engineering, 
                and web design blends technical skills with creative expression.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <div className="my-work-section">
          <h1 className="my-work-title">My Work</h1>
          <div className="work-grid">
          {sections.map((section, index) => (
              <motion.div
                key={index}
                className="card-container"
              >
                <Link to={section.link} className="card-link">
                <Card
                    title={section.title}
                    description={section.description}
                    imageUrl={section.backgroundImage}
                    theme="primary"
                    actionButtons={[
                      {
                        label: "Explore",
                        onClick: () => (window.location.href = section.link),
                        theme: "secondary",
                      },
                    ]}
                    />
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
