export const analyzeContent = (content: string, metaDescription: string, keywords: string[]) => {

    const missingKeywords = keywords.filter((keyword) =>
        !content.toLowerCase().includes(keyword.toLowerCase())
    );

    // Word and Character Count
    const wordCount = content.split(/\s+/).filter((word) => word.length > 0).length;
    const characterCount = content.replace(/\s+/g, "").length; // Exclude spaces

    // SEO Analysis
    const metaDescriptionLength = metaDescription.length;

    const keywordOccurrences = keywords.map((keyword) => {
        const regex = new RegExp(keyword.trim().replace(/\s+/g, "\\s*"), "gi");
        const count = (content.match(regex)?.length || 0);
        return { keyword, count };
    });

    const totalKeywordCount = keywordOccurrences.reduce((acc, { count }) => acc + count, 0);
    const keywordDensity = wordCount > 0 ? (totalKeywordCount / wordCount) * 100 : 0;


    return {
        seoAnalysis: { metaDescriptionLength, keywordDensity, keywordOccurrences, missingKeywords},
        wordCount,
        characterCount,
    };
};
