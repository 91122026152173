import React from "react";
import "./BlogComponents.scss";
import CircularProgress from "../../../../../Components/UI Components/CircularProgress/CircularProgress";

interface BlogAnalysisProps {
    seoAnalysis: {
        metaDescriptionLength: number;
        keywordDensity: number;
        keywordOccurrences: { keyword: string; count: number }[];
        missingKeywords: string[];
    };
    wordAndCharacterCount: {
        wordCount: number;
        characterCount: number;
    };
    seoRating: number;
}

const BlogAnalysis: React.FC<BlogAnalysisProps> = ({ seoAnalysis, wordAndCharacterCount, seoRating }) => (
    <div className="blog-analysis">
        <div className="seo-rating">
            <h2>SEO Rating</h2>
            <CircularProgress rating={seoRating} className="seo-progress" />
        </div>

        <div className="seo-analysis">
            <h2>SEO Analysis</h2>
            <p>Meta Description Length: {seoAnalysis.metaDescriptionLength} characters</p>
            <p>Keyword Density: {seoAnalysis.keywordDensity.toFixed(2)}%</p>
        </div>

        <div className="keyword-analysis">
            <h2>Keyword Analysis</h2>
            <ul>
                {seoAnalysis.keywordOccurrences?.map(({ keyword, count }) => (
                    <li key={keyword}>
                        {keyword}: {count} occurrences (
                        {((count / wordAndCharacterCount.wordCount) * 100).toFixed(2)}%)
                    </li>
                ))}
            </ul>
            <h3>Missing Keywords</h3>
            <ul>
            {seoAnalysis.missingKeywords?.map((keyword) => (
                    <li key={keyword}>{keyword}</li>
                ))}
            </ul>
        </div>

        <div className="word-character-analysis">
            <h2>Word and Character Count</h2>
            <p>Word Count: {wordAndCharacterCount.wordCount} {wordAndCharacterCount.wordCount >= 1000 ? "(Ideal for SEO)" : "(Consider increasing word count)"}</p>
            <p>Character Count: {wordAndCharacterCount.characterCount}</p>
        </div>
    </div>
);

export default BlogAnalysis;
