import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDatabase, ref, set, push, serverTimestamp, get, remove } from "firebase/database";
import { Blog } from "../../types";
import { createSlug } from "../../Utils/createSlug";

// Thunk for fetching drafts
export const fetchDraftBlogs = createAsyncThunk("blogs/fetchDrafts", async () => {
    const database = getDatabase();
    const draftsRef = ref(database, "drafts");
    const snapshot = await get(draftsRef);

    if (snapshot.exists()) {
        const data = snapshot.val();
        return Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
        }));
    } else {
        return [];
    }
});

// Thunk for deleting a draft
export const deleteBlogDraft = createAsyncThunk("blogs/deleteDraft", async (id: string) => {
    const database = getDatabase();
    const draftRef = ref(database, `drafts/${id}`);
    await remove(draftRef);
    return id; // Return the deleted draft ID
});

// Thunk for saving a draft
export const saveBlogDraft = createAsyncThunk(
    "blogs/saveDraft",
    async (blog: Blog, { rejectWithValue }) => {
      try {
        const database = getDatabase();
        const draftsRef = ref(database, "drafts");
        let draftId = blog.id;
  
        if (!draftId) {
          // Generate a new draft ID
          const newDraftRef = push(draftsRef);
          draftId = newDraftRef.key!;
        }
  
        // Save or update the draft
        await set(ref(database, `drafts/${draftId}`), {
          ...blog,
          id: draftId,
          updatedAt: serverTimestamp(),
        });
  
        return { ...blog, id: draftId }; // Return updated draft
      } catch (error: any) {
        return rejectWithValue(error.message || "Failed to save draft.");
      }
    }
);

// Thunk for updating a draft
export const updateBlogDraft = createAsyncThunk(
    "blogs/updateDraft",
    async (blog: Blog, { rejectWithValue }) => {
      try {
        const database = getDatabase();
        const draftRef = ref(database, `drafts/${blog.id}`);
  
        // Update the draft
        await set(draftRef, {
          ...blog,
          updatedAt: serverTimestamp(),
        });
  
        return blog; // Return updated draft
      } catch (error: any) {
        return rejectWithValue(error.message || "Failed to update draft.");
      }
    }
  );

  export const savePublishedBlog = createAsyncThunk(
    "blogs/savePublished",
    async (blog: Blog, { rejectWithValue }) => {
      try {
        const database = getDatabase();
        const slug = createSlug(blog.title);
        const publishedRef = ref(database, `published/${slug}`);
        await set(publishedRef, {
          ...blog,
          slug,
          publishedAt: serverTimestamp(),
        });
        return { ...blog, slug };
      } catch (error: any) {
        return rejectWithValue(error.message || "Failed to publish blog.");
      }
    }
  );

  export const fetchPublishedBlogs = createAsyncThunk(
    "blogs/fetchPublished",
    async () => {
      const database = getDatabase();
      const publishedRef = ref(database, "published");
      const snapshot = await get(publishedRef);
  
      if (snapshot.exists()) {
        const data = snapshot.val();
        return Object.keys(data).map((key) => ({
          slug: key,
          ...data[key],
        }));
      } else {
        return [];
      }
    }
  );