import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App.tsx";
import { Provider } from "react-redux";
import store, {persistor} from "./Features/store.ts";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
    <HelmetProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    </HelmetProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
