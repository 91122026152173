import React, { useState, useEffect } from "react";
import "./BlogEditor.scss";
import Button from "../Buttons/Button/Button";
import IconTextInput from "../TextInput/IconTextInput/IconTextInput";
import { FaBold, FaItalic, FaUnderline, FaListOl, FaListUl, FaLine, FaLink, FaEraser, FaAlignLeft, FaAlignRight, FaAlignCenter, FaCode } from "react-icons/fa";

type BlogEditorProps = {
  onContentChange: (content: string) => void;
  content?: string; // Accepts initial content as a prop
};

const BlogEditor: React.FC<BlogEditorProps> = ({ onContentChange, content = "" }) => {
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const [editorContent, setEditorContent] = useState<string>(content);
  const [textColor, setTextColor] = useState<string>("#000000");
  const [selectedImage, setSelectedImage] = useState<HTMLImageElement | null>(null); // Track clicked image
  const [altText, setAltText] = useState<string>("");
  const [selectedFontSize, setSelectedFontSize] = useState<string>("3");
  const [activeFormats, setActiveFormats] = useState<{ [key: string]: boolean }>({
    bold: false,
    italic: false,
    underline: false,
  });
  const [selectedBlockType, setSelectedBlockType] = useState<string>("p");
  const [selectedAlignment, setSelectedAlignment] = useState<string>("justifyLeft");
  const [viewRawHTML, setViewRawHTML] = useState(false);

  useEffect(() => {
    if (contentRef.current && content) {
      contentRef.current.innerHTML = content;
    }

    // Add click listener to handle image clicks
    const handleImageClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.tagName === "IMG") {
        const img = target as HTMLImageElement;
        setSelectedImage(img);
        setAltText(img.alt); // Set the current alt text
      }

      const handleSelectionChange = () => {
        // Check formatting state
        const bold = document.queryCommandState("bold");
        const italic = document.queryCommandState("italic");
        const underline = document.queryCommandState("underline");

        setActiveFormats({ bold, italic, underline });

        const fontSize = document.queryCommandValue("fontSize");
        setSelectedFontSize(fontSize || "3");

        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const blockElement = selection.anchorNode?.parentElement?.closest("h1, h2, h3, h4, h5, h6, p");
          setSelectedBlockType(blockElement?.tagName.toLowerCase() || "p");

          const computedAlignment = (blockElement as HTMLElement)?.style?.textAlign || "left";
          if (computedAlignment === "center") {
            setSelectedAlignment("justifyCenter");
          } else if (computedAlignment === "right") {
            setSelectedAlignment("justifyRight");
          } else {
            setSelectedAlignment("justifyLeft");
          }
        }

      };

      document.addEventListener("selectionchange", handleSelectionChange);

      return () => {
        document.removeEventListener("selectionchange", handleSelectionChange);
      };
    };

    const contentEl = contentRef.current;
    contentEl?.addEventListener("click", handleImageClick);

    return () => {
      contentEl?.removeEventListener("click", handleImageClick);
    };
  });

  const insertHorizontalLine = () => {
    document.execCommand("insertHorizontalRule");
    contentRef.current?.focus();
  };

  const toggleRawHTMLView = () => {
    setViewRawHTML((prev) => !prev);
  };

  const insertLink = () => {
    const url = prompt("Enter the URL:");
    if (url) {
      formatText("createLink", url);
    }
  };

  const applyAltText = () => {
    if (selectedImage) {
      selectedImage.alt = altText; // Update the alt attribute
      setSelectedImage(null); // Close the editor
      handleInput(); // Notify parent of content change
    }
  };

  const formatText = (command: string, value?: string) => {
    document.execCommand(command, false, value || "");
    const isActive = document.queryCommandState(command);
    setActiveFormats((prev) => ({
      ...prev,
      [command]: isActive,
    }));
    contentRef.current?.focus();
  };

  const handleAlignmentChange = (alignment: string) => {
    setSelectedAlignment(alignment);
    if (alignment === "justifyLeft") formatText("justifyLeft");
    if (alignment === "justifyCenter") formatText("justifyCenter");
    if (alignment === "justifyRight") formatText("justifyRight");
  };

  const clearFormatting = () => {
    document.execCommand("removeFormat", false, undefined); // Clears inline formatting
    formatText("formatBlock", "p"); // Resets to paragraph
  };

  const handleHeadingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const heading = e.target.value;
    if (heading === "default") {
      formatText("formatBlock", "p"); // Set to paragraph
    } else {
      formatText("formatBlock", heading); // Set to chosen heading (h1-h6)
    }
  };

  const handleInput = () => {
    const updatedContent = contentRef.current?.innerHTML || "";
    setEditorContent(updatedContent);
    onContentChange(updatedContent); // Notify parent of content change
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.ctrlKey || e.metaKey) {
      switch (e.key.toLowerCase()) {
        case "z": // Undo
          e.preventDefault();
          formatText("undo");
          break;
        case "y": // Redo
          e.preventDefault();
          formatText("redo");
          break;
        case "b": // Bold
          e.preventDefault();
          formatText("bold");
          break;
        case "i": // Italic
          e.preventDefault();
          formatText("italic");
          break;
        case "u": // Underline
          e.preventDefault();
          formatText("underline");
          break;
        default:
          break;
      }
    }
  };

  const handleTextColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedColor = e.target.value;
    setTextColor(selectedColor);
    formatText("foreColor", selectedColor); // Apply the selected color
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const size = e.target.value;
    setSelectedFontSize(size);
    formatText("fontSize", size);
  };

  const insertImageTemplate = (alignment: string, size: string) => {
    const imageUrl = prompt("Enter the image URL:");
    if (imageUrl) {
      const imgHtml = `
        <img 
          src="${imageUrl}" 
          style="display: block; ${alignment === "center" ? "margin: 10px auto;" : `float: ${alignment};`}
          width: ${size === "large" ? "80%" : size === "medium" ? "50%" : "30%"};"
          alt="Image"
        />
      `;

      // Insert the template at the cursor position
      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const templateElement = document.createElement("div");
        templateElement.innerHTML = imgHtml;
        const fragment = document.createDocumentFragment();
        while (templateElement.firstChild) {
          fragment.appendChild(templateElement.firstChild);
        }
        range.deleteContents(); // Remove any selected text or content
        range.insertNode(fragment); // Insert the new content
        range.collapse(false); // Move the cursor to the end of the inserted content
      }

      handleInput(); // Notify parent of content change
    }
  };

  const handleAltTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAltText(e.target.value);
  };

  return (
    <div className="blog-editor">
      <div className="toolbar">
        <select title="heading-select" className="heading-select" value={selectedBlockType} onChange={handleHeadingChange}>
          <option value="p">Normal</option>
          <option value="h1">Heading 1</option>
          <option value="h2">Heading 2</option>
          <option value="h3">Heading 3</option>
          <option value="h4">Heading 4</option>
          <option value="h5">Heading 5</option>
          <option value="h6">Heading 6</option>
        </select>
        <select title="font-size-select" className="font-size-select" value={selectedFontSize} onChange={handleFontSizeChange}>
          <option value="1">Small</option>
          <option value="2">Normal</option>
          <option value="3">Medium</option>
          <option value="4">Large</option>
          <option value="5">Extra Large</option>
          <option value="6">Huge</option>
          <option value="7">Massive</option>
        </select>
        <button
          type="button"
          title="Align Left"
          className={selectedAlignment === "justifyLeft" ? "active" : ""}
          onClick={() => handleAlignmentChange("justifyLeft")}
        >
          <FaAlignLeft />
        </button>
        <button
          type="button"
          title="Align Center"
          className={selectedAlignment === "justifyCenter" ? "active" : ""}
          onClick={() => handleAlignmentChange("justifyCenter")}
        >
          <FaAlignCenter />
        </button>
        <button
          type="button"
          title="Align Right"
          className={selectedAlignment === "justifyRight" ? "active" : ""}
          onClick={() => handleAlignmentChange("justifyRight")}
        >
          <FaAlignRight />
        </button>
        <input
          type="color"
          id="text-color-picker"
          value={textColor}
          onChange={handleTextColorChange}
          title="Set Text Color"
        />
        <button type="button" onClick={() => formatText("bold")} title="Bold" className={activeFormats.bold ? "active" : ""}>
          <FaBold />
        </button>
        <button type="button" onClick={() => formatText("italic")} title="Italic" className={activeFormats.italic ? "active" : ""}>
          <FaItalic />
        </button>
        <button type="button" onClick={() => formatText("underline")} title="Underline" className={activeFormats.underline ? "active" : ""}>
          <FaUnderline />
        </button>
        <button type="button" onClick={() => formatText("insertOrderedList")} title="Ordered List">
          <FaListOl />
        </button>
        <button type="button" onClick={() => formatText("insertUnorderedList")} title="Unordered List">
          <FaListUl />
        </button>
        <button type="button" onClick={insertHorizontalLine} title="Insert Horizontal Line">
          <FaLine />
        </button>
        <button type="button" onClick={insertLink} title="Insert Link">
          <FaLink />
        </button>
        <select
          title="Image Templates"
          onChange={(e) => {
            const [alignment, size] = e.target.value.split("-");
            if (alignment && size) insertImageTemplate(alignment, size);
          }}
        >
          <option value="">Insert Image Template</option>
          <option value="left-small">Align Left, Small</option>
          <option value="left-medium">Align Left, Medium</option>
          <option value="left-large">Align Left, Large</option>
          <option value="right-small">Align Right, Small</option>
          <option value="right-medium">Align Right, Medium</option>
          <option value="right-large">Align Right, Large</option>
          <option value="center-small">Center Align, Small</option>
          <option value="center-medium">Center Align, Medium</option>
          <option value="center-large">Center Align, Large</option>
        </select>
        <button title="Html-view" type="button" onClick={toggleRawHTMLView}>
          <FaCode/>
        </button>
        <button type="button" onClick={clearFormatting} title="Clear Formatting" className="clear-formatting-btn">
          <FaEraser />
        </button>
      </div>

      {viewRawHTML ? (
        <textarea
          title="html-view"
          className="raw-html-view"
          value={editorContent}
          onChange={(e) => {
            setEditorContent(e.target.value);
            onContentChange(e.target.value); // Update parent content when raw HTML changes
          }}
          rows={10}
        />
      ) : (
        <div
          ref={contentRef}
          className="content-area"
          contentEditable
          onInput={handleInput}
          onKeyDown={handleKeyDown}
        />
      )}
      {selectedImage && (
        <div className="image-editor">
          <h3>Edit Alt Text</h3>
          <IconTextInput
            type="text"
            value={altText}
            onChange={handleAltTextChange}
            placeholder="Enter alt text"
          />
          <div className="alt-text-controls">
            <Button theme="success" onClick={applyAltText}>Apply</Button>
            <Button theme="error" onClick={() => setSelectedImage(null)}>Cancel</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogEditor;
