import React, { forwardRef, MouseEvent, KeyboardEvent } from "react";
import "./fullIconBtn.scss";

interface FullIconButtonProps {
  icon?: React.ElementType;
  theme?: "primary" | "secondary" | "error" | "success" | "warning";
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  href?: string;
}

const FullIconButton = forwardRef<HTMLButtonElement, FullIconButtonProps>(
  (
    { icon: Icon, theme = "primary", onClick, children, className, disabled = false, ariaLabel, href },
    ref
  ) => {
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
        if (href) {
          window.open(href, "_blank", "noopener noreferrer");
        }
      }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
      if (!disabled && (e.key === "Enter" || e.key === " ")) {
        e.preventDefault();
        onClick?.();
      }
    };

    return (
      <button
        ref={ref}
        className={`fullIconButton ${theme} ${className || ""} ${disabled ? "disabled" : ""}`}
        disabled={disabled}
        type="button"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label={ariaLabel}
        tabIndex={0}
      >
        {Icon && (
          <span className="button-icon" aria-hidden="true">
            <Icon />
          </span>
        )}
        <span className="button-label">{children}</span>
      </button>
    );
  }
);

export default FullIconButton;
