import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Features/store';

import DashboardHeader from "./DashboardHeader/DashboardHeader";

import "./Dashboard.scss"
import Button from '../../Components/UI Components/Buttons/Button/Button';

const Dashboard: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const utilities = [
    { title: "Manage Images", description: "Upload and manage your image gallery", path: "/image-dashboard" },
    { title: "Create Blog Post", description: "Write and publish new blog posts", path: "/blog-dashboard" },
    { title: "Add Code Project", description: "Add your coding projects", path: "/projectDashboard" },
  ];

  return (<>
    <DashboardHeader />
    <div className="dashboard-container">
       <h2>{user?.email}</h2>
      <h2>Available Utilities</h2>
      <div className="utility-grid">
        {utilities.map((utility, index) => (
          <div className="utility-card" key={index}>
            <h3>{utility.title}</h3>
            <p>{utility.description}</p>
            <Button
              onClick={() => {
                window.location.href = utility.path;
              }}
              theme="secondary"
            >
              Go to {utility.title}
            </Button>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default Dashboard;
