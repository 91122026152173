import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import './VideoModal.scss';
import SmIconButton from '../../UI Components/Buttons/SmallIconButton/SmallIconButton';

interface VideoModalProps {
  videoID: string;
  videoTitle: string;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({ videoID, videoTitle, onClose }) => {
  useEffect(() => {
    // Lock scrolling when the modal is open
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return ReactDOM.createPortal(
    <motion.div
      className="modal"
      onClick={onClose}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <SmIconButton
          className="close-button"
          icon={FaTimes}
          ariaLabel="Close button"
          onClick={onClose}
        />
        <iframe
          title={videoTitle}
          width="1080"
          height="500"
          src={`https://www.youtube.com/embed/${videoID}?autoplay=1`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </motion.div>
    </motion.div>,
    document.getElementById('widget-portal') as HTMLElement
  );
};

export default VideoModal;
