import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./Auth/AuthSlice";
import blogReducer from "./Blog/BlogSlice";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "blogs"], // Add `blogs` to the whitelist
};

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  blogs: blogReducer,
});

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Persistor for Redux Persist
export const persistor = persistStore(store);

// Export typed hooks and types
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;

export default store;
