import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDraftBlogs, deleteBlogDraft, saveBlogDraft, updateBlogDraft, fetchPublishedBlogs, savePublishedBlog} from "./BlogThunk";
import { Blog, BlogState } from "../../types";

// Initial state
export const initialState: BlogState = {
  drafts: [],
  published: [],
  loading: false,
  error: null,
};

// Blog slice
const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch drafts
      .addCase(fetchDraftBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDraftBlogs.fulfilled,
        (state, action: PayloadAction<Blog[]>) => {
          state.drafts = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchDraftBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch drafts.";
      })
      // Delete draft
      .addCase(
        deleteBlogDraft.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.drafts = state.drafts.filter(
            (draft) => draft.id !== action.payload
          );
        }
      )
      .addCase(deleteBlogDraft.rejected, (state, action) => {
        state.error = action.error.message || "Failed to delete draft.";
      })
      // Save draft
      .addCase(saveBlogDraft.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBlogDraft.fulfilled, (state, action: PayloadAction<Blog>) => {
        const existingDraftIndex = state.drafts.findIndex(
          (draft) => draft.id === action.payload.id
        );
    
        if (existingDraftIndex > -1) {
          // Update existing draft
          state.drafts[existingDraftIndex] = action.payload;
        } else {
          // Add new draft
          state.drafts.push(action.payload);
        }
    
        state.loading = false;
      })
      .addCase(saveBlogDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Update draft
      .addCase(updateBlogDraft.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateBlogDraft.fulfilled, (state, action: PayloadAction<Blog>) => {
        const draftIndex = state.drafts.findIndex(
          (draft) => draft.id === action.payload.id
        );
    
        if (draftIndex > -1) {
          state.drafts[draftIndex] = action.payload; // Update draft
        }
    
        state.loading = false;
      })
      .addCase(updateBlogDraft.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchPublishedBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublishedBlogs.fulfilled, (state, action: PayloadAction<Blog[]>) => {
        state.published = action.payload;
        state.loading = false;
      })
      .addCase(fetchPublishedBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch published blogs.";
      })
      // Save Published Blog
      .addCase(savePublishedBlog.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePublishedBlog.fulfilled, (state, action: PayloadAction<Blog>) => {
        if (!state.published) {
          state.published = []; // Initialize if undefined
        }
        state.published.push(action.payload); // Push the new blog
        state.loading = false;
      })
      .addCase(savePublishedBlog.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default blogSlice.reducer;
