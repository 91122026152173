import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import "./BlogPage.scss";
import Button from "../../../Components/UI Components/Buttons/Button/Button";

const BlogPage: React.FC = () => {
    const navigate = useNavigate();
    const { slug } = useParams<{ slug: string }>();
    const [blog, setBlog] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const db = getDatabase();
                const blogRef = ref(db, `published/${slug}`);
                const snapshot = await get(blogRef);

                if (snapshot.exists()) {
                    setBlog(snapshot.val());
                } else {
                    setBlog(null);
                }
            } catch (error) {
                console.error("Error fetching blog:", error);
                setBlog(null);
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [slug]);

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (!blog) {
        return <div className="not-found">Blog not found</div>;
    }

    const handleGoBack = () => {
        navigate("/Blog")
    }
    return (
        <div className="blog-page">
            <>
                <Button className="go-back-btn" theme="primary" onClick={handleGoBack}>Back</Button>
                <Helmet>
                    <title>{blog.title}</title>
                    <meta name="author" content={blog.author} />
                    <meta name="description" content={blog.ogDescription} />
                    <meta property="og:title" content={blog.title} />
                    <meta property="og:description" content={blog.ogDescription} />
                    <meta property="og:image" content={blog.metaImage} />
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content={`https://davinchiupka.com/blog/${slug}`} />
                    {blog.metaKeywords && <meta name="keywords" content={blog.metaKeywords} />}
                </Helmet>
                <header className="blog-header">
                    <h1>{blog.title}</h1>
                    <div className="blog-info">
                        <p className="timestamp">
                            Published: {new Date(blog.timestamp).toLocaleDateString()}
                        </p>
                    </div>
                </header>
                <article className="blog-content">
                    <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                </article>
            </>
        </div>
    );
};

export default BlogPage;
