import React, { useState, ComponentType, InputHTMLAttributes, useRef } from "react";
import "./FileUpload.scss";
import FullIconButton from "../Buttons/FullIconButton/fullIconBtn";

interface FileUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: ComponentType;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  ariaLabel?: string;
  ariaDescription?: string;
  theme?: "primary" | "secondary" | "error" | "success" | "warning";
  disabled?: boolean;
  showPreview?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  icon: Icon,
  placeholder = "Upload a file",
  className,
  readOnly = false,
  ariaLabel,
  ariaDescription,
  theme,
  disabled,
  showPreview = false,
  ...props
}) => {
  const [fileName, setFileName] = useState<string | null>(null);

  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    fileInput.current?.click(); // Safely trigger the file input click
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
    } else {
      setFileName(null);
    }
  };

  return (
    <div className={`file-upload-box ${theme || ""} ${className || ""} ${disabled ? "disabled" : ""}`}>
      <input
        type="file"
        className="file-input"
        ref={fileInput}
        aria-label={ariaLabel || placeholder}
        aria-describedby={ariaDescription ? "file-description" : undefined}
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleFileChange}
        {...props}
      />
      <FullIconButton className="file-label" onClick={handleClick} disabled={disabled} icon={Icon}>
        {fileName || placeholder}
      </FullIconButton>
      {showPreview && fileName && <span className="file-preview">{fileName}</span>}
      {ariaDescription && (
        <span id="file-description" className="sr-only">
          {ariaDescription}
        </span>
      )}
    </div>
  );
};

export default FileUpload;
