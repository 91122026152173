import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "../../Hooks/useDispatch";
import { fetchPublishedBlogs } from "../../Features/Blog/BlogThunk";
import { AnimatePresence } from "framer-motion";
import { RootState } from "../../Features/store";
import Card from "../../Components/Cards/SimpleCard/SimpleCard";
import { motion } from "framer-motion";
import heroImage from "../../assets/HeroImages/blog-hero.jpg"; // Replace with your hero image
import "./BlogHome.scss";

const BlogHome: React.FC = () => {
    const dispatch = useDispatch();
    const { published: blogs, loading, error } = useSelector(
        (state: RootState) => state.blogs
    );

    useEffect(() => {
        dispatch(fetchPublishedBlogs());
    }, [dispatch]);

    const blogVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
    };

    return (
        <div className="blog-home">
            <motion.div
                className="hero-section"
                style={{ backgroundImage: `url(${heroImage})` }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, ease: "easeOut" }}
            >
                <motion.div
                    className="hero-content"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                >
                    <h1 className="hero-title">Explore My Latest Blogs</h1>
                </motion.div>
                <div className="overlay"></div>
            </motion.div>

            <div className="blog-list">
                {loading && <div className="loading">Loading blogs...</div>}
                {error && <div className="error">Error: {error}</div>}
                {!loading && !error && blogs.length === 0 && (
                    <div className="no-blogs">No blogs available</div>
                )}
                <AnimatePresence>
                    {!loading &&
                        !error &&
                        blogs.map((blog) => (
                            <motion.div
                                key={blog.slug}
                                variants={blogVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                transition={{ duration: 0.5, ease: "easeOut" }}
                            >
                                <Card
                                    title={blog.title}
                                    description={blog.seoDescription}
                                    imageUrl={blog.metaImage}
                                    actionButtons={[
                                        {
                                            label: "Read More",
                                            onClick: () =>
                                                (window.location.href = `/blog/${blog.slug}`),
                                            theme: "primary",
                                        },
                                    ]}
                                >
                                    <small>
                                        Published on {new Date(blog.publishedAt as unknown as string).toLocaleDateString()}
                                    </small>
                                </Card>
                            </motion.div>
                        ))}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default BlogHome;
