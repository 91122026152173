import React, { useState, useEffect } from 'react';
import './ImagePage.scss';
import Pager from '../../Components/Pager/Pager';
import { motion, AnimatePresence } from "framer-motion";
import heroImage from "../../assets/HeroImages/HangingTreeMoss.jpg"
import ImageModal from '../../Components/Modals/ImageModal/ImageModal';
import { getDownloadURL, ref, listAll } from 'firebase/storage';
import { storage } from '../../firebase';

interface ImageData {
  url: string;
  alt: string;
}

const Image: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<ImageData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<ImageData[]>([]);
  const itemsPerPage = 12;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const folderRef = ref(storage, "GalleryImages");
        const result = await listAll(folderRef);
        const imageData: ImageData[] = await Promise.all(
          result.items.map(async (item, index) => {
            const url = await getDownloadURL(item);
            return { url, alt: `Image ${index + 1}` }; // Generate Alt text dynamically
          })
        );
        setImages(imageData);
      } catch (e) {
        console.error("Error fetching images from Firebase Storage:", e);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = images.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="image-container">
      <motion.div
        className="hero-section"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <motion.div
          className="hero-content"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <h1>Welcome to the World Through My Lens</h1>
        </motion.div>
        <div className="overlay"></div>
      </motion.div>
      <div className="gallery-container">
        {loading ? (
          <motion.div
            className="loading-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="spinner"></div>
            <h1>Loading Images...</h1>
          </motion.div>
        ) : (
          <>
            <Pager
              className="pager-container"
              totalItems={images.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />

            <AnimatePresence mode="wait">
              <motion.div
                key={currentPage}
                className="image-gallery"
                initial={{ opacity: 0, width: '100%' }}
                animate={{ opacity: 1, width: '100%' }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.5, ease: 'easeOut' }}
              >
                {currentItems.map((image, index) => (
                  <img
                    key={index}
                    src={image.url}
                    alt={image.alt}
                    className="gallery-image"
                    onClick={() => setSelectedImage(image)}
                    loading="lazy"
                  />
                ))}
              </motion.div>
            </AnimatePresence>

            <Pager
              className="pager-container"
              totalItems={images.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          </>
        )}
      </div>
      {selectedImage && (
        <ImageModal
          className="image-modal"
          imageUrl={selectedImage.url}
          imageTitle={selectedImage.alt}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
};

export default Image;
