import React, { forwardRef, MouseEvent, KeyboardEvent} from 'react';
import Tooltip from '../../ToolTip/ToolTip';
import './SmallIconButton.scss';

interface SmIconButtonProps {
  icon: React.ComponentType;
  theme?: 'primary' | 'secondary' | 'error' | 'success' | 'clear'| 'warning';
  href?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  ariaLabel?: string;
  tooltip?: string; // Optional tooltip text
}

const SmIconButton = forwardRef<HTMLButtonElement, SmIconButtonProps>(
  (
    { icon: Icon, theme = "primary", href, onClick, className, disabled = false, ariaLabel, tooltip },
    ref
  ) => {

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
        if (href) {
          window.open(href, '_blank', 'noopener noreferrer');
        }
      }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
      if (!disabled && (e.key === 'Enter' || e.key === ' ')) {
        e.preventDefault();
        onClick?.();
      }
    };

    const button = (
      <button
        ref={ref}
        className={`sm-icon-button ${theme} ${className || ""} ${disabled ? 'disabled' : ''}`}
        rel={href && !disabled ? "noopener noreferrer" : undefined}
        type="button"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        aria-label={ariaLabel}
        tabIndex={0}
        disabled={disabled}
      >
        <Icon />
      </button>
    );

    return tooltip ? (
      <Tooltip content={tooltip} position={"left"}>
        {button}
      </Tooltip>
    ) : (
      button
    );
  }
);

export default SmIconButton;
