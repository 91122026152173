import React, { ReactNode, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector} from "react-redux";
import { useDispatch } from "../../Hooks/useDispatch";
import { RootState } from "../../Features/store";
import { setLoading } from "../../Features/Auth/AuthSlice";

interface AuthRouteProps {
  children: ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { loading, user } = useSelector((state: RootState) => state.auth);
  const [isTimedOut, setIsTimedOut] = useState(false);

  useEffect(() => {
    // Set a timeout to limit the waiting period
    const timeoutId = setTimeout(() => {
      setIsTimedOut(true);
      if (loading) {
        dispatch(setLoading(false)); // Set loading to false if timed out
      }
    }, 5000); // 5 seconds timeout

    return () => clearTimeout(timeoutId); // Cleanup the timeout
  });

  if (loading && !isTimedOut) {
    // Show a loading state while waiting
    return <div className="loading">Checking authentication...</div>;
  }

  if (isTimedOut) {
    // If timeout occurs, redirect to login or show an error
    return <Navigate to="/login" replace />;
  }

  return user ? <>{children}</> : <Navigate to="/login" replace />;
};

export default AuthRoute;
