import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Button from '../../Components/UI Components/Buttons/Button/Button';
import './PageNotFound.scss';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  const goHome = () => navigate('/');

  // Framer Motion Variants
  const containerVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren", // Ensures the container fades in before children animations
        staggerChildren: 0.2, // Staggers children animations
      },
    },
  };

  const itemVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { type: 'spring', stiffness: 200, damping: 20 } },
  };

  return (
    <motion.div 
      className='not-found-container'
      variants={containerVariant}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="not-found-content" variants={itemVariant}>
        <motion.h1 variants={itemVariant}>404</motion.h1>
        <motion.h2 variants={itemVariant}>Oops! Page Not Found</motion.h2>
        <motion.p variants={itemVariant}>
          The page you are looking for might have been removed, had its name changed, 
          or is temporarily unavailable.
        </motion.p>
        <motion.div variants={itemVariant}>
          <Button 
            theme="primary"
            onClick={goHome}
            className="my-link-class"
          >Go to Home</Button>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default PageNotFound;
