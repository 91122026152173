import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
} from "firebase/auth";
import { auth } from "../../firebase";
import { setUser, setLoading, setError, logout } from "./AuthSlice";
import { SerializableUser } from "../../types";

export const initializeAuthState = createAsyncThunk<void>(
  "auth/initializeAuthState",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    onAuthStateChanged(auth, (user: User | null) => {
      if (user) {
        const serializedUser: SerializableUser = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        };
        dispatch(setUser(serializedUser));
      } else {
        dispatch(logout());
      }
      dispatch(setLoading(false));
    });
  }
);

export const login = createAsyncThunk<
  void,
  { email: string; password: string }
>("auth/login", async ({ email, password }, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const serializedUser: SerializableUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
    };
    dispatch(setUser(serializedUser));
  } catch (error: any) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
});

export const logoutUser = createAsyncThunk<void>(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await signOut(auth);
      dispatch(logout());
    } catch (error: any) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  }
);
