import { storage } from "../../firebase";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
  updateMetadata,
} from "firebase/storage";

// Upload Image
export const uploadImage = async (file: File, path: string): Promise<string> => {
  const storageRef = ref(storage, path);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};

// Get All Images in Folder
export const listImages = async (folder: string): Promise<string[]> => {
  const folderRef = ref(storage, folder);
  const result = await listAll(folderRef);
  return Promise.all(result.items.map((item) => getDownloadURL(item)));
};

// Delete Image
export const deleteImage = async (path: string): Promise<void> => {
  const storageRef = ref(storage, path);
  await deleteObject(storageRef);
};

// Update Image Metadata
export const updateImageMetadata = async (
  path: string,
  metadata: { [key: string]: string }
): Promise<void> => {
  const storageRef = ref(storage, path);
  await updateMetadata(storageRef, metadata);
};
