import React, { ReactNode } from 'react';
import Button from "../../UI Components/Buttons/Button/Button";
import SmIconButton from '../../UI Components/Buttons/SmallIconButton/SmallIconButton';
import { IconType } from 'react-icons';
import './SimpleCard.scss';

interface ActionButton {
  label: string;
  onClick: () => void;
  icon?: IconType;
  theme?: 'primary' | 'secondary' | 'error' | 'success' | 'clear' | 'warning';
}

interface CardProps {
  theme?: 'primary' | 'secondary';
  title: string;
  description?: string;
  imageUrl?: string;
  className?: string;
  actionButtons?: ActionButton[];
  useIconButtons?: boolean;
  children?: ReactNode;
}

const Card: React.FC<CardProps> = ({
  theme = "primary",
  title,
  description = "",
  imageUrl,
  className = "",
  actionButtons = [],
  useIconButtons = false,
  children,
}) => {
  return (
    <div className={`card ${theme} ${className}`}>
      <div className="card-content">
        {imageUrl && (
          <div className="card-image">
            <img src={imageUrl} alt={title} />
          </div>
        )}
        <h3 className="card-title">{title}</h3>


        {description && (
          <p className="card-description">{description}</p>
        )}

        {children && (
          <div className="card-children">
            {children}
          </div>
        )}

        {actionButtons && (
          <div className="card-actions">
            {actionButtons.map((button, index) => {
              if (useIconButtons && button.icon) {
                return (
                  <SmIconButton
                    key={index}
                    icon={button.icon}
                    onClick={button.onClick}
                    className="action-button"
                    theme={button.theme || "clear"}
                    ariaLabel={button.label}
                  />
                );
              }
              return (
                <Button
                  key={index}
                  onClick={button.onClick}
                  className="action-button"
                  theme="secondary"
                >
                  {button.label}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
