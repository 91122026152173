import React from "react";
import "./CircularProgress.scss";

interface CircularProgressProps {
    rating: number;
    className?: string;
}

const getProgressStatus = (rating: number): string => {
    if (rating <= 0) return "default"; // Red
    if (rating <= 40) return "error"; // Red
    if (rating <= 70) return "warning"; // Orange
    return "success"; // Green
};

const CircularProgress: React.FC<CircularProgressProps> = ({ rating, className }) => {
    const progressStatus = getProgressStatus(rating); // Determine the class based on progress

    return (
        <div className={`circular-progress ${className || ""}`}>
            <div className={`circle ${progressStatus}`} data-rating={rating}>
                <span className="rating-text">{rating}%</span>
            </div>
        </div>
    );
};

export default CircularProgress;
